import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Select } from "octane-spark";

import copy from "../copy.json";

const MIN_ENGINE_NUMBER = 0;
const MAX_ENGINE_NUMBER = 2;


export const MarineEnginesNumber = ({ value, disabled, onChange }) => {

    const options = Array.from({ length: MAX_ENGINE_NUMBER - MIN_ENGINE_NUMBER + 1 }, (_, index) => `${index + MIN_ENGINE_NUMBER}`);
    const handleChange = useCallback((value) => {
        onChange(parseInt(value));
    }, [onChange]);
    return (
        <Select
            id="select-marine-engines-number"
            label={copy.fields.marineEnginesNumber.label}
            placeholder={copy.fields.marineEnginesNumber.placeholder}
            value={value === null ? '' : `${value}`}
            disabled={disabled}
            onChange={handleChange}
        >
            {options.map((option) => (
                <Select.Option key={option} value={option}>
                    {option}
                </Select.Option>
            ))}
        </Select>
    );
};

MarineEnginesNumber.propTypes = {
    value: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};
