import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { TextInput } from "octane-spark";

import copy from "../copy.json";

export const MarineModelWriteIn = ({ value, disabled, onChange }) => {

    const handleChange = useCallback((value) => {
        const modelName = value;
        onChange({ id: 'none', name: modelName });
    }, [onChange]);

    return (
        <TextInput
            id="write-in-marine-model"
            label={copy.fields.marineModelWriteIn.label}
            placeholder={copy.fields.marineModelWriteIn.placeholder}
            value={value === 'none' ? '' : value}
            disabled={disabled}
            onChange={handleChange}
        />
    );
};

MarineModelWriteIn.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
